@import '../vars.scss';
@import '../mixins.scss';

.main__wr {
  padding: 0 16px;
}

.main-section__slide {
  position: relative;
}

.main-section__slider-img {
  width: 100%;
  height: 518px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 17% 12%;
  }
}

.main-section__slider-img-dark {
  display: none;
}

.main-section__slider-text {
  position: absolute;
  top: 44%;
  left: 75px;
  z-index: 1;
}

.main-section__slider-title {
  font-weight: 700;
  font-size: rem(40);
  line-height: 1.1;
  margin: 0;
}

.main-section__slider-desc {
  font-weight: 400;
  font-size: rem(14);
  line-height: 1.42;
}

.main-section__navigation {
  position: absolute;
  top: 46%;
  left: 17.5px;
  left: 25px;
  right: 25px;
  z-index: 2;
  height: 53px;
}

.main-section__navigation-item {
  cursor: pointer;
  padding: 10px;

  svg {
    color: $white;
  }

  &_prev {
    float: left;
  }

  &_next {
    float: right;
  }
}

@include media('ts') {
  .main-section .wr {
    padding: 0;
  }

  .main-section__slider-img img {
    height: 100%;
    object-fit: cover;
    object-position: 50% 20%;
  }

  .main-section__slider-img-dark {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 580px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .main-section__slider-text {
    top: unset;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

@include media('ml') {
  .main-section__slider-title {
    font-size: rem(20);
  }

  .main-section__slider-desc {
    font-size: rem(12);
    margin-top: 5px;
  }

  .main-section__navigation {
    left: 11px;
    right: 11px;
  }
}