@import '../vars.scss';
@import '../mixins.scss';

header {
  padding: 47px 0 16px;
  text-align: center;
}

.header__navs {
  margin: 41px auto 0;
  max-width: 415px;
  width: 100%;
  text-align: center;
}

.header__nav {
  font-family: $ff;
  font-weight: 500;
  font-size: rem(14);
  line-height: 1.21;
  transition: color $as $ae;

  &:hover {
    color: $orange;
  }
}

@include media('mm') {
  .header__navs {
    max-width: 200px;
    margin: 24px auto 0;
  }

  .header__nav {
    font-size: rem(12);
  }

  .header__logo {
    transform: scale(0.8);
  }
  
}