@import '../vars.scss';
@import '../mixins.scss';

.history-section {
  padding-top: 75px;
}

.history__wr {
  flex-wrap: nowrap;
  padding: 0 17px;
}

.history__desc {
  text-align: center;
  margin-right: 10%;
}

.history__title {
  font-weight: 400;
  font-size: rem(30);
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0;
}

.history__subtitle {
  font-family: $ff1;
  font-weight: 400;
  font-size: rem(15);
  line-height: 1.375;
  color: $pink;
  margin: 31px 0 0;
}

.history__text {
  font-family: $ff1;
  font-weight: 400;
  font-size: rem(16);
  line-height: 1.25;
  margin: 35px 0 0;
  max-width: 687px;
  width: 100%;
  text-align: left;
}

.history__img {
  max-width: 771px;
  width: 100%;
}

@include media('ml') {
  .history-section {

    .wr {
      padding: 0;
    }
  }

  .history__wr {
    flex-direction: column-reverse;
  }

  .history__img {
    margin: 0 0 30px;
  }

  .history__desc {
    text-align: center;
    margin-right: 0;
    padding: 0 20px;
  }
}

@include media('mm') {
  .history-section {
    padding-top: 40px;

    .wr {
      padding: 0;
    }
  }

  .history__desc {
    padding: 0 28px;
  }

  .history__title {
    font-family: $ff1;
    font-size: rem(16);
  }

  .history__subtitle {
    margin-top: 15px;
    font-size: rem(9);
  }

  .history__text {
    margin-top: 15px;
    font-size: rem(9);
  }
}