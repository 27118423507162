/* FONTS */

  /* font family */
  $ff:  'Inter', 'monospace', sans-serif;
  $ff1: 'CenturyGothic', sans-serif;

  $fs: 14;  // font size
  $lh: 2.4; // line height

/* END FONTS */

/* GRID */

  $space:     8;                      // spacing grid
  $grid_pad:  15;                     // padding column
  $wr_pad:    29;                     // wrapper padding
  $big_pad:   8.43;                   // big padding
  $page_wr:   1570 + ($wr_pad*2) ;    // max-width content

  /* media */
  $media: (

    desktop_l:  2560,   // desktop long
    desktop_m:  1920,   // desktop middle
    desktop_s:  1440,   // desktop small

    tablet_l:   1280,   // tablet long
    tablet_m:   1024,   // tablet middle
    tablet_s:   900,    //tablet small

    mobile_l:   768,    // mobile long
    mobile_m:   475,    // mobile middle
    mobile_s:   375,    // mobile small
    mobile_xs:  320,    // mobile extra small (not used)

  );

/* END GRID */

/* COLOR */
  $black:       #1A1A1A;
  $white:       #fff;
  $pink:        #E92174;
  $orange:      #CC400B;

/* END COLOR */


/* ANIMATE */

  $as: 0.45s;     // animation speed
  $ar: $as*50;    // animation rotation
  $ae: ease-out;  // animation easing

/* END ANIMATE */