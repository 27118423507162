
.row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	flex-wrap: wrap;
}


/* .row + .row{margin-top: ($grid_pad*2)+px;} */
.row.center {
	align-items: center;
}

.row.space-between {
	justify-content: space-between;
}

.row.justify-center {
	justify-content: center;
}

.row.flex-end {
	justify-content: flex-end;
}

.row.stretch {
	align-items: stretch;
}

.row.no-wrap {
	flex-wrap: nowrap;
}

.row.row-reverse {
	flex-direction: row-reverse;
}

.row.row_no-flex {
	display: block;
}

.row.row_no-flex *[class*="col-"] {
	float: left;
}

.row.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

@include gridColumn();

@include media ('mobile_l') {
	@include gridColumn(8)
}

@include media ('mm') {
	@include gridColumn(4)
}