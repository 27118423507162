@font-face {
  font-family: 'CenturyGothic';
  src: local('CenturyGothic'), local('CenturyGothic'),
      url('../fonts/CenturyGothic.woff2') format('woff2'),
      url('../fonts/CenturyGothic.woff') format('woff'),
      url('../fonts/CenturyGothic.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter Medium'),
      url('../fonts/Inter-Medium.woff2') format('woff2'),
      url('../fonts/Inter-Medium.woff') format('woff'),
      url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
	font-family: "Inter";
	src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
  url('../fonts/Inter-SemiBold.woff') format('woff'),
  url('../fonts/Inter-SemiBold.ttf') format('truetype'); /* Modern Browsers */
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Inter";
	src: url('../fonts/Inter-Bold.woff2') format('woff2'),
  url('../fonts/Inter-Bold.woff') format('woff'),
  url('../fonts/Inter-Bold.ttf') format('truetype'); /* Modern Browsers */
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Inter";
	src: url('../fonts/Inter-Black.woff2') format('woff2'),
  url('../fonts/Inter-Black.woff') format('woff'),
  url('../fonts/Inter-Black.ttf') format('truetype'); /* Modern Browsers */
	font-weight: 800;
	font-style: normal;
}