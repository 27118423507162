@import '../vars.scss';
@import '../mixins.scss';

.footer {
  padding-top: 200px;
}

.footer__wr {
  padding: 0 33px;
}

.footer__img {
  height: 500px;
  margin-bottom: 40px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.footer__inst {
  text-align: center;
}

.footer__inst-link {
  font-weight: 400;
  font-size: rem(30);
  line-height: 1.43;
  text-transform: uppercase;
  display: block;
}

.footer__text-link {
  transition: all $as $ae;

  &:hover {
    color: $orange;
  }
}

.footer__posts {
  margin-top: 37px;
}

.footer__post {
  flex-basis: calc((100% - 54px) / 4);
  width: 100%;
}

.footer__post-link {
  display: block;
}

.footer__text {
  font-weight: 500;
  font-size: rem(12);
  line-height: 1.42;
  text-align: center;
  margin: 39px 0 124px;
  transition: all $as $ae;
  text-transform: uppercase;

  &:hover {
    color: $orange;
  }
}

@include media('ts') {
  .footer__post {
    flex-basis: calc((100% - 8px) / 2);
    margin-bottom: 12px;
  }

  .footer__text {
    margin-top: 27px;
  }
}

@include media('ml') {
  .footer {
    padding-top: 12px;
  }

  .footer__inst-link {
    font-size: rem(12);
  }

  .footer__posts {
    margin-top: 17px;
  }

  .footer__text {
    margin: 0 0 166px;
    font-size: rem(12);
  }

}