html, 
body {
  margin: 0; 
  padding: 0; 
  max-width: 100%;
  background: $black; 
  color: $white;
  font-size: $fs+px;
  font-style: normal;
  font-weight: normal;
  font-family: $ff;
  -webkit-tap-highlight-color: transparent; 
  /* overflow-x: hidden; */
}

body {
  position: relative;
  min-width: 360px;
}

img {
  max-width: 100%; 
  user-select: none; 
  pointer-events: none;
}
a img {border: 0;}

a {
  text-decoration: none; 
  color: $white;
}
a span, 
a font {cursor: inherit;}

hr {
  background: $white;
  height: 0.15rem; 
  border: 0; 
  margin: 1rem 0;
}
label {cursor: pointer; user-select: none;}

input[type="submit"], 
button {cursor: pointer;}

textarea {resize: vertical;}

input, 
textarea, 
button {
  font-family: inherit; 
  color: inherit; 
  outline: 0;
}
button::-moz-focus-inner {
  padding: 0; 
  border: 0;
}

script {display: none !important;}

::selection {
  color: $white; 
  background: transparentize($pink, 0.35);
}

*, 
*:before, 
*:after {box-sizing: border-box;}

section {
  position: relative;
  margin: 0;
}
