@import '../vars.scss';
@import '../mixins.scss';

.vision-section {
  padding-top: 138px;
}

.vision__wr {
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;
}

.vision__item {
  text-align: center;
  max-width: 408px;
  width: 100%;
  margin-bottom: 40px;

  &:nth-child(3) {
    max-width: 100%;
  }
}

.vision__item-material,
.vision__item-desc {
  font-family: $ff1;
  font-weight: 400;
  font-size: rem(18);
  line-height: 1.11;
}

.vision__item-material {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.vision__item-img {
  margin-top: 40px;
  height: 612px;

  img {
    height: 100%;
    object-fit: cover;
  }
}

.vision__item-text-mobile {
  display: none;
}

@include media('ts') {
  .vision__wr {
    flex-direction: column-reverse;
    align-items: center;
  }

  .vision__item {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .vision__item-text-mobile {
    display: block;
  }

  .vision__item-img {
    height: unset;

    img {
      height: unset;
    }
  }
}

@include media('ml') {
  .vision-section {
    padding-top: 28px;
  }

  .vision__item {
    margin-bottom: 20px;
  }

  .vision__item-material,
  .vision__item-desc {
    font-size: rem(12);
  }

  .vision__item-material {
    margin-bottom: 10px;
  }

  .vision__item-img {
    margin-top: 10px;
  }

  .vision__wr {
    padding: 0 24px;
  }
}

@include media('mm') {
}