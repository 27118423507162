@import '../vars.scss';
@import '../mixins.scss';

.capsule-section {
  padding-top: 112px;
}

.capsule__title {
  font-weight: 400;
  font-size: rem(30);
  line-height: 1;
  color: $pink;
  text-align: center;
  margin: 0;

  & ~ .capsule__title {
    margin-top: 50px;
  }
}

.capsule__slider,
.capsule__slider-2024 {
  margin-top: 25px;
}

.capsule__pagination {
  margin: 46px auto 0;
  max-width: 770px;
  width: 100%;
}

.capsule__pagination-number-slide,
.capsule__pagination-number-slide {
  font-weight: 400;
  font-size: rem(12);
  line-height: 1.42;
}

.capsule__slider-pagination-progressbar-wr {
  position: relative;
  flex: 1 1 auto;
  max-width: 715px;
  width: 100%;
  margin-right: -15px;
}

.capsule__slider-pagination-progressbar {
  position: absolute;
  top: 8px;
  right: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: white;
  max-width: 700px;
  width: 100%;
}

.capsule__slider-pagination-progressbar2 {
  position: absolute;
  top: 8px;
  right: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: white;
  max-width: 700px;
  width: 100%;
}

.capsule__pagination-progressbar .swiper-scrollbar-drag,
.capsule__pagination-progressbar-2024 .swiper-scrollbar-drag {
  cursor: pointer;
  width: 15px!important;
  height: 15px;
  margin-top: 1.9px;
  background: radial-gradient(closest-side, #FFFFFF 0%, #FFFFFF 40.62%, #FFFFFF 46.88%, rgba(255, 255, 255, 0) 49%, rgba(255, 255, 255, 0) 100%);
  background-color: $white;
}

@include media('ts') {
  .capsule__pagination-number-slide,
  .capsule__pagination-number-slide-2024 {
    white-space: nowrap;
    margin-right: 20px;
  }

  .capsule__pagination {
    padding: 0 32px 0 17px;

  }
}

@include media('ml') {
  .capsule-section {
    padding-top: 35px;
  }

  .capsule__title {
    font-family: $ff1;
    font-weight: 400;
    font-size: rem(16);
    max-width: 215px;
    width: 100%;
    margin: 0 auto;
  }

  .capsule__slider {
    margin-top: 14px;
    padding: 0 19px;
  }

}