@import '../vars.scss';
@import '../mixins.scss';

.about-section {
  padding-top: 90px;
}

.about__video {
  text-align: center;
  margin: 0 auto 90px;
  max-width: 1281px;
  width: 100%;

  iframe,
  .about__video-mobile {
    max-width: 1281px;
    width: 100%;
    aspect-ratio: 16/9;
  }

  .about__video-mobile {
    display: none;
  }

  img {
    max-width: 1281px;
    width: 100%;
  }
}

.about__video-desctop {
  width: 100%;
}

.my-video-dimensions {
  width: 100%;
  height: 100%;
}

.about__text {
  text-align: center;
  padding: 0 17px;
}

.about__text-subtitle {
  font-weight: 400;
  font-size: rem(14);
  line-height: 1.21;
  margin: 0;
}

.about__text-title {
  margin: 3px 0 0;
  font-weight: 400;
  font-size: rem(30);
  line-height: 1;
  color: $pink;
}

.about__text-text {
  font-family: $ff1;
  font-weight: 400;
  font-size: rem(16);
  line-height: 1.25;
  max-width: 745px;
  width: 100%;
  margin: 28px auto 0;
}

@include media('ml') {
  .about-section {
    padding-top: 34px;
  }

  .about__video {
    margin-bottom: 34px;

    .about__video-mobile {
      display: block;
    }

    .about__video-desctop {
      display: none;
    }
  }

  .about__text-subtitle {
    font-size: rem(8);
  }

  .about__text-title {
    margin-top: 15px;
    font-family: $ff1;
    font-size: rem(16);
    font-weight: 400;
  }

  .about__text-text {
    margin-top: 15px;
    font-size: rem(9);
  }
}